import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import tmta from './tmta.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoRef: [
    {
      logo: tmta,
      // current domain name
      url: window.location.protocol + "//" + window.location.host + "/tmta",
    }
  ],
  qrImg,
  seo: {
    siteDescription: 'THE Co-Founder OF Reinnoverse Corporation Co., Ltd.',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#5D3D75',
    bgColor: '#161831',
    secondColor: '#5D3D75'
  },
  UserData: {
    userCompany: 'Reinnoverse Corporation Co., Ltd.',
    userImg,
    userName: 'Pinyanan Charoensriphong عاءشه',
    userInfo: 'THE Co-Founder OF Reinnoverse',
    userWebsite: 'https://reinnoverse.com/',
    nameWebsite: 'www.reinnoverse.com',
    phoneNumber: '+66952956599',
  },
  SocialData: {
    contactFile,
    email: 'Ann.pinyanan@gmail.com',
    facebook: 'https://www.facebook.com/Reinnoverse.co',
    line: 'https://line.me/ti/p/HKPBGVV7v6',
    linkedIn: '',
    whatsapp: 'https://wa.me/qr/IFYALREUZCJ7L1',
  },
  DescriptionCard: {
    topic1: 'About',
    info1: "We can improve your business strategy and presence with modern digital communication tools. In today’s fast growing digital world, you have to put your business on the map.",
    topic2: 'Interest',
    info2: `
    What we want first is to know you and understand your business. Based on your activity and your goals we will provide you digital solutions to accelerate and improve your actual services. But we will also look at new opportunities for you and guide you to take advantage of them.`,
    descriptionHeight: 500
  },
}