import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../company-logo.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  qrImg,
  logoRef: [
    {
      logo: logo,
      // current domain name
      url: window.location.protocol + "//" + window.location.host + "/tmta",
    }
  ],
  seo: {
    siteDescription: 'TMTA - Thai Muslim Trade Association',
  },
  ARlink: [
    { title: 'English', link: 'https://mywebar.com/p/Project_6_axesxbs4pc267719874210' },
    { title: 'العربية‎', link: 'https://mywebar.com/p/Project_6_axesxbs4pc26771987' },
    { title: 'ไทย', link: 'https://mywebar.com/p/Project_6_axesxbs4pc2677' }
  ],
  themeColor: {
    mainColor: '#0F6047',
    bgColor: '#022e2a',
    secondColor: '#2AB472'
  },
  UserData: {
    userCompany: 'Thai Muslim Trade Association (TMTA)',
    userImg,
    userName: 'Marut Mekloy',
    userInfo: 'Lingua-preneur & Serial Success Seeker President',
    userWebsite: 'https://www.marutmekloy.com',
    nameWebsite: 'www.marutmekloy.com',
    phoneNumber: '+66858263300',
  },
  SocialData: {
    contactFile,
    email: 'empowercreative@gmail.com',
    facebook: 'https://www.facebook.com/marut.mekloy',
    line: '',
    linkedIn: 'https://www.linkedin.com/in/marut-mekloy-55786847/',
    whatsapp: '',
  },
  DescriptionCard: {
    topic1: 'About me',
    info1: `A language enthusiast by nature, Mr. Marut Mekloy has provided professional translation, interpretation and copywriting services for virtually all top advertising agencies in Thailand and a few creative houses around the world. He has also served more than 200 internationally-renowned brands across the globe. For more information about Mr. Marut Mekloy and his professional services, please visit (www.empower.in.th).  
    In July 2022, he was elected the new President of the Thai Muslim Trade Association (TMTA), an organization that aspires to be the center of coordination, collaboration and promotion of business opportunities for Muslim businesspeople and Halal entrepreneurs in Thailand. For more information about TMTA, please visit (www.thaimuslimtrade.com)`,
    topic2: 'Interest',
    info2: `
    We are passionated about future and digital technologies. We are friends and business partners who came together to help you to improve your products and services in a fast growing digital world.`,
    descriptionHeight: 900
  },
}