import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
import tmta from '../tmta.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
    {
      logo: tmta,
      url: window.location.protocol + "//" + window.location.host + "/tmta",
    }
  ],
  qrImg,
  seo: {
    siteDescription: 'TheIconGroup',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#e0738c',
    bgColor: '#871117',
    secondColor: '#c51f3d'
  },
  UserData: {
    userCompany: 'The Icon Group Co., Ltd.',
    userImg,
    userName: 'Jitrat Piandee',
    userInfo: 'Dealer',
    userWebsite: 'https://piandee1986.theicongroup.co.th/',
    nameWebsite: 'www.The Icon group.com',
    phoneNumber: '+66955244413',
  },
  SocialData: {
    contactFile,
    email: 'timpiandee@gmail.com',
    facebook: 'https://www.facebook.com/profile.php?id=100084128348487',
    line: 'https://line.me/ti/p/DaeTPwoSeh',
    linkedIn: '',
    whatsapp: '',
    youtube: 'https://www.youtube.com/c/TheiConGroup',
    tiktok: 'tiktok.com/@fa_trangcha',
  },
  DescriptionCard: {
    topic1: 'About us',
    info1: `The Icon Group Co., Ltd. sells dietary supplements. that has been certified by the FDA
    in the form/distributor system both online and offline Legitimate certified by Products
    received international standards GMP, ISO,HACCP, Halal and FDA (FDA) have.
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 300
  },
}