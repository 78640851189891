import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Toei from "./components/Reinnoverse/Toei";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
//Metanet
import ToeiMetanet from "./components/Metanet/Toei";
import ArnoldMetanet from "./components/Metanet/Arnold";
import JoyMetanet from "./components/Metanet/Joy";
import ValenMetanet from "./components/Metanet/Valen";
import MarkMetanet from "./components/Metanet/Mark";
import TaoMetanet from "./components/Metanet/Tao";
// Chiwadi
import SarapeeYuadyong from "./components/Chiwadi/SarapeeYuadyong";
// SPC FACTORY
import Premrapee from "./components/SpcFactory/Premrapee";
// TMTA
import Marut from "./components/TMTA/Marut";
import TMTA from "./components/TMTA";
// iBev
import MaleeMankong from "./components/iBev/MaleeMankong";
//Pinyanan
import Pinyanan from "./components/Reinnoverse/Pinyanan";
import Bushror from "./components/BarakatLunlaland/Bushror";
import Nathanan from "./components/WellnessLife/Nathanan";
import Jitrat from "./components/TheIconGroup/Jitrat";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TMTA />} />
        {/* METANET */}
        <Route path="/metanet/toei" element={<ToeiMetanet />} />
        <Route path="/metanet/arnold" element={<ArnoldMetanet />} />
        <Route path="/metanet/Supasiri" element={<JoyMetanet />} />
        <Route path="/metanet/Arisman" element={<MarkMetanet />} />
        <Route path="/metanet/Napatsorn" element={<ValenMetanet />} />
        <Route path="/metanet/Isaman" element={<TaoMetanet />} />
        {/* TMTA */}
        <Route path="/TMTA" element={<TMTA />} />
        <Route path="/TMTA/Marut" element={<Marut />} />
        {/* iBev */}
        <Route path="/TMTA/iBev" element={<MaleeMankong />} />
        <Route path="/TMTA/MaleeMankong" element={<MaleeMankong />} />
        <Route path="/iBev/MaleeMankong" element={<MaleeMankong />} />
        <Route path="/iBev" element={<MaleeMankong />} />
        {/* Chiwadi */}
        <Route path="/TMTA/Chiwadi" element={<SarapeeYuadyong />} />
        <Route path="/TMTA/SarapeeYuadyong" element={<SarapeeYuadyong />} />
        <Route path="/Chiwadi/SarapeeYuadyong" element={<SarapeeYuadyong />} />
        <Route path="/Chiwadi" element={<SarapeeYuadyong />} />
        {/* Riceberry */}
        {/* SPC FACTORY  */}
        <Route path="/TMTA/Spcfactory" element={<Premrapee />} />
        <Route path="/Spcfactory" element={<Premrapee />} />
        <Route path="/Spcfactory/Premrapee" element={<Premrapee />} />
        {/* Pinyanan */}
        <Route path="/reinnoverse/pinyanan" element={<Pinyanan />} />
        <Route path="/Pinyanan" element={<Pinyanan />} />
        <Route path="/TMTA/reinnoverse" element={<Pinyanan />} />
        <Route path="/reinnoverse" element={<Pinyanan />} />
        {/* Barakat */}
        <Route path="/TMTA/Barakat" element={<Bushror />} />
        <Route path="/Barakat" element={<Bushror />} />
        <Route path="/Barakat/Bushror" element={<Bushror />} />
        {/* WellnessLife */}
        <Route path="/TMTA/WellnessLife" element={<Nathanan />} />
        <Route path="/WellnessLife" element={<Nathanan />} />
        <Route path="/WellnessLife/Nathanan" element={<Nathanan />} />
        {/* TheIconGroup */}
        <Route path="/TMTA/TheIconGroup" element={<Jitrat />} />
        <Route path="/TheIconGroup" element={<Jitrat />} />
        <Route path="/TheIconGroup/Jitrat" element={<Jitrat />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
