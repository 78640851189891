import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
  ],
  qrImg,
  seo: {
    siteDescription: 'METANET Corporation',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#6734c0',
    bgColor: '#140025',
    secondColor: '#2c0e73'
  },
  UserData: {
    userCompany: 'METANET CORPORATION CO., LTD.',
    userImg,
    userName: 'Witsarut Chaipest (Arnold)',
    userInfo: 'Business Strategy Director',
    userWebsite: 'https://metanetcorporation.com/',
    nameWebsite: 'MetanetCorporation.com',
    phoneNumber: '+66953969998',
  },
  SocialData: {
    contactFile,
    email: 'Arnold@metanetcorporation.com',
    facebook: '',
    line: 'https://line.me/ti/p/fOiF4qoHoc',
    tiktok: '',
    linkedIn: '',
    whatsapp: '',

  },
  DescriptionCard: {
    topic1: 'About',
    info1: `Arnold is a born manager. He loves to create new projects. His financial expertise also places him as a key strategist in any project . Arnold is a father a husband and an ambitious business entrepreneur. You would love to meet him.
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 270
  },
}