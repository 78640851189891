import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
import tmta from '../tmta.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
    {
      logo: tmta,
      url: window.location.protocol + "//" + window.location.host + "/tmta",
    }
  ],
  qrImg,
  seo: {
    siteDescription: 'Barakat Lunlaland',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#5c9b40',
    bgColor: '#144e08',
    secondColor: '#2a774e'
  },
  UserData: {
    userCompany: 'Barakat Lunlaland',
    userImg,
    userName: 'Bushror Samanloh',
    userInfo: 'The founder of Barakat',
    userWebsite: 'https://maps.app.goo.gl/aqauR4ffpM5efuwx5?g_st=ic',
    nameWebsite: 'Barakat Lunlaland Google Map',
    phoneNumber: '+66933819111',
  },
  SocialData: {
    contactFile,
    email: '',
    facebook: 'https://www.facebook.com/BarakatLunlaLand',
    line: 'https://lin.ee/EGGE3Ma',
    tiktok: 'https://www.tiktok.com/@barakat_lunla_land?_t=8X4KnGcbOLJ&_r=1',
    linkedIn: '',
    whatsapp: 'https://wa.me/message/57KQ5GN2NU5NM1',

  },
  DescriptionCard: {
    topic1: 'About us',
    info1: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 370
  },
}