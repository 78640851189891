import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
  ],
  qrImg,
  seo: {
    siteDescription: 'METANET Corporation',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#6734c0',
    bgColor: '#140025',
    secondColor: '#2c0e73'
  },
  UserData: {
    userCompany: 'METANET CORPORATION CO., LTD.',
    userImg,
    userName: 'Isaman Sangbamrung (Tao)',
    userInfo: 'Project Manager',
    userWebsite: 'https://metanetcorporation.com/',
    nameWebsite: 'MetanetCorporation.com',
    phoneNumber: '+66942257558',
  },
  SocialData: {
    contactFile,
    email: 'Isaman.S@metanetcorporation.com',
    facebook: '',
    line: '',
    tiktok: '',
    linkedIn: '',
    whatsapp: '',

  },
  DescriptionCard: {
    topic1: 'About',
    info1: `Isaman in software Engineer expert in infrastructure AI & Machine Learning infrastructure and DevOps culture. Isaman passionate about cutting edge technology.
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 240
  },
}