import * as React from 'react';
import emailIcon from '../img/email.png';
import contactsIos from '../img/contacts_ios.png';
import linkedIn from '../img/linkedIn.png';
import facebook from '../img/facebook.png';
import tiktok from '../img/tiktok.png';
import youtube from '../img/youtube.png';
import instagram from '../img/instagram.png';
import line from '../img/line.png';
// import discord from '../img/discord.png';
// import wechat from '../img/wechat.png';
import whatsapp from '../img/whatsapp.png';

export const LinkButtonGroup = (props: { siteTitle: string, contactFile?: any, email: string, phone: string, linkedIn: string, facebook: string, line: string, whatsapp: string, tiktok?: string, youtube?: string, instagram?: string }) => {
  return (
    <div>
      <div className='link-button group mb-2'>
        {props.email && <a href={"mailto:" + props.email} rel="noopener noreferrer" >
          <div className="blur blink" style={{ width: 40, height: 40, backgroundColor: '#21e9ff46', right: 1, top: -1 }}></div>
          <img alt="email" src={emailIcon} width={47} height={47}></img>
        </a>}
        {(!props.contactFile || props.phone )&& <a href={props.contactFile ? props.contactFile : "tel:" + props.phone} download={props.siteTitle + '.vcf'} rel="noopener noreferrer" >
          <div className="blur blink" style={{ width: 38, height: 38, backgroundColor: '#21e9ff46', right: 1, top: -1 }}></div>
          <img alt="phone" src={contactsIos} width={47} height={47}></img>
        </a>}
        {props.linkedIn && <a href={props.linkedIn} target="_blank" rel="noopener noreferrer" >
          <div className="blur blink" style={{ width: 40, height: 40, backgroundColor: '#21e9ff46', right: 1, top: -1 }}></div>
          <img alt="linkedin" src={linkedIn} width={47} height={47}></img>
        </a>}
        {/* </div>
      <div className='link-button group'> */}
        {props.facebook && <a href={props.facebook} target="_blank" rel="noopener noreferrer" >
          <div className="blur blink" style={{ width: 40, height: 40, backgroundColor: '#21e9ff46', right: 1, top: -1 }}></div>
          <img alt="facebook" src={facebook} width={47} height={47}></img>
        </a>}
        {props.instagram && <a href={props.instagram} target="_blank" rel="noopener noreferrer" >
          <div className="blur blink" style={{ width: 40, height: 40, backgroundColor: '#21e9ff46', right: 1, top: -1 }}></div>
          <img alt="instagram" src={instagram} width={47} height={47}></img>
        </a>}
        {props.tiktok && <a href={props.tiktok} target="_blank" rel="noopener noreferrer" >
          <div className="blur blink" style={{ width: 40, height: 40, backgroundColor: '#21e9ff46', right: 1, top: -1 }}></div>
          <img alt="tiktok" src={tiktok} width={47} height={47}></img>
        </a>}
        {props.youtube && <a href={props.youtube} target="_blank" rel="noopener noreferrer" >
          <div className="blur blink" style={{ width: 40, height: 40, backgroundColor: '#21e9ff46', right: 1, top: -1 }}></div>
          <img alt="youtube" src={youtube} width={47} height={47}></img>
        </a>}
        {props.line && <a href={props.line} target="_blank" rel="noopener noreferrer" >
          <div className="blur blink" style={{ width: 40, height: 40, backgroundColor: '#21e9ff46', right: 1, top: -1 }}></div>
          <img alt="line" src={line} width={47} height={47}></img>
        </a>}
        {props.whatsapp && <a href={props.whatsapp} target="_blank" rel="noopener noreferrer" >
          <div className="blur blink" style={{ width: 40, height: 40, backgroundColor: '#21e9ff46', right: 1, top: -1 }}></div>
          <img alt="whatsapp" src={whatsapp} width={47} height={47}></img>
        </a>}
      </div>
    </div>
  )
}