import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
  ],
  qrImg,
  seo: {
    siteDescription: 'METANET Corporation',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#6734c0',
    bgColor: '#140025',
    secondColor: '#2c0e73'
  },
  UserData: {
    userCompany: 'METANET CORPORATION CO., LTD.',
    userImg,
    userName: 'Supasiri Praisupa (Joy)',
    userInfo: 'Growth Director & Co-Founder(MProjext)',
    userWebsite: 'https://metanetcorporation.com/',
    nameWebsite: 'MetanetCorporation.com',
    phoneNumber: '+66951462633',
  },
  SocialData: {
    contactFile,
    email: 'Joy.supasiri@gmail.com',
    facebook: '',
    line: '',
    tiktok: '',
    linkedIn: '',
    whatsapp: '',

  },
  DescriptionCard: {
    topic1: 'About',
    info1: ` Specialized in creating people who can build new business unit for your organisation. Finding new way of work and method for company's process improvement.
    Including marketing communication to build customer experiences.
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 270
  },
}