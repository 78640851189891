import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
  ],
  qrImg,
  seo: {
    siteDescription: 'METANET Corporation',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#6734c0',
    bgColor: '#140025',
    secondColor: '#2c0e73'
  },
  UserData: {
    userCompany: 'METANET CORPORATION CO., LTD.',
    userImg,
    userName: 'Arisman Meteepatipan (Mark)',
    userInfo: 'Strategic Partner Director',
    userWebsite: 'https://metanetcorporation.com/',
    nameWebsite: 'MetanetCorporation.com',
    phoneNumber: '+66623727792',
  },
  SocialData: {
    contactFile,
    email: 'arisman.s2h@gmail.com',
    facebook: '',
    line: 'https://line.me/ti/p/xQPn4H9rq7',
    tiktok: '',
    linkedIn: '',
    whatsapp: '',

  },
  DescriptionCard: {
    topic1: 'About',
    info1: `I’m passionate about utilizing Business combined with Technology to make valuable products and services for my customer. I highly love communicating and working with teammates are completed projects together.
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 250

  },
}