import HTMLReactParser from "html-react-parser"

export const DescriptionCard = (props: { height: number, topic1: string, info1: string, topic2: string, info2: string }) => {
  return (<div className='description-card mx-auto mt-2'>
    <div className='bg' style={{ height: props.height + 'px' }}></div>
    <div className='header'>
      <div className='about-icon shadow' style={{ backgroundColor: '#f94eff' }}></div>
      <div className='about-icon front'>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="mt-1 bi bi-person-fill" viewBox="0 0 16 16">
          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg>
      </div>
      <p >{props.topic1}</p>
    </div>
    <p className='info'>{HTMLReactParser(props.info1)}</p>
    {props.topic2 &&
      <>
        <div className='header'>
          <div className='about-icon shadow' style={{ backgroundColor: '#FFD30C' }}></div>
          <div className='about-icon front'>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#FFD30C" className="mt-1 bi bi-star-fill" viewBox="0 0 16 16">
              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
            </svg>
          </div>
          <p >{props.topic2}</p>
        </div>
        <p className='info'>{HTMLReactParser(props.info2)}</p>
      </>
    }
  </div>)
}