import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
  ],
  qrImg,
  seo: {
    siteDescription: 'METANET Corporation',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#6734c0',
    bgColor: '#140025',
    secondColor: '#2c0e73'
  },
  UserData: {
    userCompany: 'METANET CORPORATION CO., LTD.',
    userImg,
    userName: 'Kantpong Poonkasem (เต้ย)',
    userInfo: 'CEO & Co-Founder',
    userWebsite: 'https://metanetcorporation.com/',
    nameWebsite: 'MetanetCorporation.com',
    phoneNumber: '+66853124092',
  },
  SocialData: {
    contactFile,
    email: 'toeikanta@gmail.com',
    facebook: '',
    line: 'https://line.me/ti/p/S3aWaJLQPX',
    tiktok: '',
    linkedIn: '',
    whatsapp: '',

  },
  DescriptionCard: {
    topic1: ' Hello',
    info1: `We are software house company, we specialize in developing cutting-edge software applications tailored to meet the unique needs of businesses in the digital era. With a dedicated team of professionals skilled in software development, user experience design, and project management, we are committed to delivering robust and scalable solutions. Partner with us to streamline operations, enhance customer engagement, and leverage emerging technologies for your business's growth and success. Welcome to Metanet Corporation, where innovation meets excellence.
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 480
  },
}