import { IOrgTemplate01 } from "../Template/OrgTemplate01/OrgTemplate01";
import { data as MarutData } from './Marut/data';
import logo from './company-logo.png';

export const OrgData: IOrgTemplate01 = {
  themeColor: {
    mainColor: MarutData.themeColor.mainColor,
    secondColor: MarutData.themeColor.secondColor,
    bgColor: MarutData.themeColor.bgColor,
  },
  seo: {
    siteTitle: "Thai Muslim Trade Association (TMTA)",
    siteDescription: "Thai Muslim Trade Association (TMTA)",
  },
  orgData: {
    name: "Thai Muslim Trade Association (TMTA)",
    logo,
    qrcode: require('./qr.png'),
  },
  userData: [
    {
      title: "President of TMTA",
      data: require('./Marut/data').data,
      url: 'marut',
    },
    {
      title: "Secretary of the TMTA association",
      data: require('../iBev/MaleeMankong/data').data,
      url: 'ibev',
    },
    {
      title: "International Trading, IT & Innovation Department",
      data: require('../Reinnoverse/Pinyanan/data').data,
      url: 'reinnoverse',
    },
    {
      title: "Business Strategy Department",
      data: require('../SpcFactory/Premrapee/data').data,
      url: 'SpcFactory',
    },
    {
      title: "Member of TMTA",
      data: require('../Chiwadi/SarapeeYuadyong/data').data,
      url: 'chiwadi',
    },
    {
      title: "Member of TMTA",
      data: require('../BarakatLunlaland/Bushror/data').data,
      url: 'Barakat',
    },
    {
      title: "Member of TMTA",
      data: require('../TheIconGroup/Jitrat/data').data,
      url: 'TheIconGroup',
    },
    {
      title: "Member of TMTA",
      data: require('../WellnessLife/Nathanan/data').data,
      url: 'WellnessLife',
    }
  ]
}