import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import tmta from '../../TMTA/company-logo.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  qrImg,
  logoRef: [
    {
      logo: tmta,
      // current domain name
      url: window.location.protocol + "//" + window.location.host + "/tmta",
    }
  ],
  seo: {
    siteDescription: 'THE FOUNDER OF I BEV  ( Thailand ) Co., Ltd.',
  },
  ARlink: [{
    title: 'English',
    link: 'https://mywebar.com/p/Project_7_chfy682df288195495?_ga=2.76202391.300991292.1664895484-1429640708.1664895484',
  }],
  themeColor: {
    mainColor: '#ffb700',
    bgColor: '#E29301',
    secondColor: '#E6A00F'
  },
  UserData: {
    userCompany: 'I BEV  ( Thailand ) Co., Ltd.',
    userImg,
    userName: 'Maree Mankong',
    userInfo: 'กรรมการผู้จัดการ MD',
    userWebsite: 'https://www.ibev.co.th',
    nameWebsite: 'www.ibev.co.th',
    phoneNumber: '+66917768203',
  },
  SocialData: {
    contactFile,
    email: 'malee@ibev.co.th',
    facebook: 'https://www.facebook.com/IBEV.co.th/',
    line: '',
    linkedIn: '',
    whatsapp: '',
  },
  DescriptionCard: {
    topic1: 'About',
    info1: `บริษัท ไอเบฟ (ประเทศไทย) จำกัด ได้ก่อตั้งขึ้นมาด้วยกับเเนวคิดของผู้บริหารที่ต้องการผลิตเครื่องดื่มที่มีคุณภาพ ได้รับการรับรองตามมาตตรฐานต่างๆ โดยเฉพาะเครื่องหมายฮาลาล ของมุสลิมที่ได้รับการยอมรับไปทั่วโลก`,
    topic2: '',
    info2: ``,
    descriptionHeight: 250
  },
}