import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
import tmta from '../tmta.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
    {
      logo: tmta,
      url: window.location.protocol + "//" + window.location.host + "/tmta",
    }
  ],
  qrImg,
  seo: {
    siteDescription: 'SPC FACTORY ALL SUPPLY',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#126aa7',
    bgColor: '#080c42',
    secondColor: '#0e115763'
  },
  UserData: {
    userCompany: 'SPC FACTORY ALL SUPPLY Co.,Ltd',
    userImg,
    userName: 'Premrapee Phanwuangdiyukul',
    userInfo: 'Managing Director',
    userWebsite: 'https://www.spcallsupply.com/',
    nameWebsite: 'www.spcallsupply.com',
    phoneNumber: '+66955542824',
  },
  SocialData: {
    contactFile,
    email: 'saroh04@yahoo.com',
    facebook: 'https://www.facebook.com/SpcAllSupply',
    line: 'https://line.me/ti/p/JIl5gs1sCW',
    linkedIn: '',
    whatsapp: '',
  },
  DescriptionCard: {
    topic1: 'About us',
    info1: `SPC FACTORY SUPPLY and affiliated factories.
    We produce various products of dietary supplements, cosmetics, and completed fertilizers in OEM and ODM license and distribution agreement. We commit to deliver One Stop Service with regulatory and industry compliance standards of GHP, Codex GHP, and ISO which can be exported and distributed to international markets.
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 370
  },
}