import { Helmet } from "react-helmet";
import { ITemplate01 } from "../Template01";
import './OrgTemplate01.css';
interface IUserData {
  title: string,
  name?: string,
  desc?: string,
  url: string,
  data: ITemplate01,
}
export interface IOrgTemplate01 {
  themeColor: {
    mainColor: string,
    secondColor: string,
    bgColor: string
  },
  seo: {
    siteTitle?: string,
    siteDescription: string,
  },
  orgData: {
    name: string,
    logo: string,
    qrcode?: string,
  },
  userData: IUserData[]
}
export const OrgTemplate01 = (props: { data: IOrgTemplate01 }) => {
  const { siteTitle, siteDescription } = props.data.seo;
  const { logo, } = props.data.orgData;
  // @ts-ignore
  // const linearStyle = { background: `transparent linear-gradient(180deg, ${themeColor.bgColor}00 0%, ${themeColor.bgColor}4d 40%, ${themeColor.bgColor}b4 55%, ${themeColor.bgColor} 80%) 0% 0% no-repeat padding-box` } as React.CSSProperties;
  const _themeColor = {
    "--secondColor": props.data.themeColor.secondColor,
    "--mainColor": props.data.themeColor.mainColor,
    "--bgColor": props.data.themeColor.bgColor,
  } as React.CSSProperties;
  const _siteTitle = siteTitle
  return (
    <div id="org-template-01" className="App" style={_themeColor}>
      <Helmet>
        <title>{_siteTitle}</title>
        <meta property="og:title" content={siteTitle}></meta>
        <meta name="description" content={siteDescription} />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:image" content={logo} />
        <link rel="icon" href={logo} />
      </Helmet>
      <div className="App-bg"></div>
      <div className="card-container mx-auto">
        <img className="org-logo" src={logo} height="200" alt="" />
        <img className="logo-bg-1" src={logo} height="200" alt="" />
        <img className="logo-bg-2" src={logo} height="200" alt="" />
        <img className="logo-bg-3" src={logo} height="200" alt="" />
        {props.data.orgData.qrcode &&
          <a
            style={{ position: 'relative', zIndex: '10' }}
            href={props.data.orgData.qrcode}
            download={_siteTitle + ".png"}
            rel="noreferrer"
          >
            <img className="mb-4" src={props.data.orgData.qrcode} height="200" alt="" />
          </a>
        }
        <div className="org-card">
          {props.data.userData.map((item, index) => {
            return (
              <div className="user-list" key={index} onClick={() => {
                // go to link
                window.location.href = `/tmta/${item.url}`
              }}>
                <div className="user-image">
                  <img className="user drop-shadow" src={item.data.UserData.userImg} alt="" />
                  <img className="logo" src={item.data.logo} alt="" />
                </div>
                <div className="user-info">
                  <h1>{item.title}</h1>
                  <h2>{item.name || item.data.UserData.userName}</h2>
                  <p>{item.desc || item.data.UserData.userCompany}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}