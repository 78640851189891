import { useState } from 'react'
import ar from '../../img/augmented-reality_2.png'
import './ARButton.css'
export interface IARButton {
  title: string,
  link: string,
}
export const ARButton = (props: { data: IARButton[] }) => {
  // create react state to show popup
  const [showPopup, setShowPopup] = useState(false)
  const onARButtonClicked = () => {
    if (props.data.length === 1) {
      window.open(props.data[0].link, '_blank')
    } else if (props.data.length > 1) {
      setShowPopup(true)
    }
  }

  if (props.data.length === 0){
    return <></>
  }

  return <div className="ar-button" >
    {showPopup && <div className="ar-popup">
      <div className="ar-popup-content" onClick={() => setShowPopup(false)}>
        <div className="ar-popup-header">
          <h3>Choose your language:</h3>
        </div>
        <div className="ar-popup-body">
          {props.data.map((ar, index) => <a key={index} className="ar-popup-item" href={ar.link}>
            <h4>{ar.title}</h4>
          </a>)}
        </div>
      </div>
    </div>}
    <div className="ar-bg" onClick={onARButtonClicked}>
      <span>AR</span>
      <img id="augmented-reality_2" alt='' src={ar} />
    </div>
  </div>

}