import React, { useEffect, useRef } from "react";

interface Props {
  name: string;
  logo: string;
  squareColor: string;
  dotColor1: string;
  dotColor2: string;
  width: number;
  height: number;
  backgroundColor: string;
}

const QRCodeComponent = (props: Props) => {
  const canvasRef = useRef(null);
  // @ts-ignore
  const qrCode = new QRCodeStyling({
    width: props.width,
    height: props.height,
    data: window.location.href,
    margin: 5,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 6 },
    dotsOptions: {
      type: "dots",
      color: "#6a1a4c",
      gradient: {
        type: "linear",
        rotation: 0,
        colorStops: [
          { offset: 0, color: props.dotColor1 },
          { offset: 1, color: props.dotColor2 },
        ],
      },
    },
    backgroundOptions: { color: props.backgroundColor },
    image: props.logo,
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: {
      type: "extra-rounded",
      color: props.squareColor,
      gradient: null,
    },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: props.squareColor,
        color2: props.squareColor,
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "dot", color: props.squareColor },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: props.squareColor,
        color2: props.squareColor,
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: props.backgroundColor,
        color2: props.backgroundColor,
        rotation: "0",
      },
    },
  });
  useEffect(() => {
    qrCode.append(canvasRef.current);
    // add rounded border style to qr code
    // @ts-ignore
    canvasRef.current.firstChild.style.borderRadius = "15px";
    return () => {
      // qrCode.destroy(); // Cleanup the QRCodeStyling instance when the component unmounts
      // @ts-ignore
      if (canvasRef.current) canvasRef.current.innerHTML = "";
    };
  }, []);

  const onClick = () => {
    // @ts-ignore
    qrCode.download({ name: props.name, extension: "png" });
  };
  return <div style={{height : props.height , marginBottom: "30px"}} onClick={onClick} ref={canvasRef}></div>;
};

export default QRCodeComponent;
