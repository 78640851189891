import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
import tmta from '../tmta.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
    {
      logo: tmta,
      url: window.location.protocol + "//" + window.location.host + "/tmta",
    }
  ],
  qrImg,
  seo: {
    siteDescription: 'WELLNESS LIFE',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#e7c36b',
    bgColor: '#9c7f03',
    secondColor: '#deac60'
  },
  UserData: {
    userCompany: 'WELLNESS LIFE CO.,LTD',
    userImg,
    userName: 'Nathanan Thongdeewong',
    userInfo: 'FOUNDER & PRESIDENT OF WELLNESS LIFE',
    userWebsite: 'https://www.siamwellnesslife.com',
    nameWebsite: 'www.siamwellnesslife.com',
    phoneNumber: '+66957482495',
  },
  SocialData: {
    contactFile,
    email: 'info@wellnesslife.world',
    facebook: 'https://www.facebook.com/kaoveeplus.brand?_rdc=1&_rdr',
    line: 'https://lin.ee/7XN9L8W',
    tiktok: '',
    linkedIn: '',
    whatsapp: '',
    youtube: 'https://www.youtube.com/channel/UC_5iGwV4UUBipDls_IYTENw',
    instagram: 'https://www.instagram.com/kaoveeplus/',
  },
  DescriptionCard: {
    topic1: 'About us',
    info1: `Wellness Life creates agricultural products to innovative agriculture.
    Received an award from iCAN
    2020,Canada. <br/>
    Innovation Agriculture Wellness Life jointly researches with highly qualified research teams,
    government, private sectors and international
    research teams in order to develop medicinal plants to be
    a health product of the future.
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 380
  },
}