import userImg from './user_img.png';
import qrImg from './qr.png';
import logo from '../logo.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  qrImg,
  logoRef: [
    {
      logo: logo,
      // current domain name
      url: window.location.protocol + "//" + window.location.host + "/tmta",
    }
  ],
  seo: {
    siteDescription: 'THE FOUNDER OF CHIWADI PRODUCTS CO.,LTD.',
  },
  ARlink: [{
    title: 'English',
    link: 'https://mywebar.com/p/Chiwadi',
  }],
  themeColor: {
    mainColor: '#b52427',
    bgColor: '#8B1C20',
    secondColor: '#C9594A'
  },
  UserData: {
    userCompany: 'CHIWADI PRODUCTS CO.,LTD.',
    userImg,
    userName: 'Sarapee Yuadyong',
    userInfo: 'THE FOUNDER OF CHIWADI',
    userWebsite: 'https://chiwadi.com/',
    nameWebsite: 'www.chiwadi.com',
    phoneNumber: '+66818422193',
  },
  SocialData: {
    contactFile,
    email: '',
    facebook: 'https://www.facebook.com/chiwadiproducts/',
    line: 'https://lin.ee/fsO9dj0',
    linkedIn: '',
    whatsapp: '',
  },
  DescriptionCard: {
    topic1: 'About',
    info1: "Chiwadi means “ Good Life” in Thai language. The company started from passion in loving Thai foods, its culture and the great desire to continue genuine Thai coconut sugar whereby it was hardly available a decade ago. Whilst, the markets have been growing, Chiwadi entered with authentic Thai taste and world class innovations forcing farming and harvesting to be free from chemicals.",
    topic2: 'Interest',
    info2: `
    We paid interest in natural changes and craft hand harvest technique, jump into all difficulties to unlock problems to cherish small communities from the best soil at Maeklong-Thajean basin. We studied researches to prove clinically our coconut flower products are low glycemic index, improve muscular mass development, retard carbohydrate absorption, promote quality of health. These were proven from 18 international and local awards mostly science and technological aspects.
    We are proud to life up lives of minority and create healthier choice who follow Chiwadi philosophy to nurture environment. We are confident that Chiwadi Products are charming and tasty that create Good Life protecting our loved ones from risks in NCD diseases.`,
    descriptionHeight: 950
  },
}