import userImg from './user_img.png';
import logo from '../logo.png';
import logoGray from '../logo_gray.png';
// @ts-ignore
import contactFile from './contact.vcf';
import { ITemplate01 } from '../../Template/Template01';

export const data: ITemplate01 = {
  logo,
  logoGrayScale: logoGray,
  logoRef: [
  ],
  seo: {
    siteDescription: 'METANET Corporation',
  },
  ARlink: [],
  themeColor: {
    mainColor: '#6734c0',
    bgColor: '#140025',
    secondColor: '#2c0e73'
  },
  UserData: {
    userCompany: 'METANET CORPORATION CO., LTD.',
    userImg,
    userName: 'Napatsorn (Valen)',
    userInfo: 'Graphic Designer',
    userWebsite: 'https://metanetcorporation.com/',
    nameWebsite: 'MetanetCorporation.com',
    phoneNumber: '+66968070415',
  },
  SocialData: {
    contactFile,
    email: 'Napatsorn28377@gmail.com',
    facebook: 'https://www.facebook.com/napatsorn.seangpor/',
    line: 'https://line.me/ti/p/QOk6rsAQNs',
    tiktok: '',
    linkedIn: '',
    whatsapp: '',

  },
  DescriptionCard: {
    topic1: 'About',
    info1: `Hello I’m Graphic designer. My working experience has helped me to develop my imagination power and creativity. Now I can create any kind of unique designs overnight that attract many clients and help me to get my desirable work. My designs are not only unique but also simple and eye-soothing.
    `,
    topic2: '',
    info2: `
    `,
    descriptionHeight: 320
  },
}